import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class DateService {
	date2api(date: Date): string {
		return date.toISOString();
	}

	date2ymd(date: Date): string {
		const offset = date.getTimezoneOffset();
		const newDate = new Date(date.getTime() - offset * 60 * 1000);
		return newDate.toISOString().split('T')[0];
	}

	convertDate(date: Date): string {
		return date.toString().split('T')[0];
	}

	convertDateRange(date: string): string {
		if (!date) return '';

		let split = date.split(' - ');
		split = split.map((v) => {
			const dateToFormat = new Date(v);

			return Intl.DateTimeFormat('pl-PL').format(dateToFormat);
		});

		return split.join(' - ');
	}

	convertDateToPolish(date: string): string {
		if (!date) return '';
		const converted = new Date(date);
		return Intl.DateTimeFormat('pl-PL').format(converted);
	}
}
